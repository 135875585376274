<template>
	<div>
	<div id="account-actions" class="-logged">
        <div class="x-button-actions" id="account-actions-mobile">
          <div class="-outer-wrapper">
            <div class="-left-wrapper">
              <a
                href="javascript:void(0);"
                class="-item-wrapper -deposit js-account-approve-aware"
                data-toggle="modal"
                data-target="#depositModal"
                @click="checkPromotions()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icdeposit.png"
                  />
                  <img
                    src="../assets/images/icdeposit.png"
                    class="-ic-img"
                    alt="EZ Casino รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ฝากเงิน</span>
              </a>

              <a
                href="javascript:void(0);"
                class="-item-wrapper -withdraw js-account-approve-aware"
                data-toggle="modal"
                data-target="#withdrawModal"
                @click="moveCreditToWallet()"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/icwithdraw.png"
                  />
                  <img
                    src="../assets/images/icwithdraw.png"
                    class="-ic-img"
                    alt="EZ Casino รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">ถอนเงิน</span>
              </a>
            </div>

            <router-link to="/play" class="-center-wrapper">
              <div
                class="-selected"
                style="background-image: url('https://firebasestorage.googleapis.com/v0/b/funhome-bc4cd.appspot.com/o/images%2FArtboard%201%20copy.png?alt=media&token=64920a47-7373-4b57-b353-4bf337a39c82')"
              >
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-top.png"
                  class="-top-curve lazyload"
                  alt="EZ Casino คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  data-src="../assets/images/shiba888-curve-bottom.png"
                  class="-bottom-curve lazyload"
                  alt="EZ Casino คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
                />
              </div>
            </router-link>
            <div
              class="-fake-center-bg-wrapper"
              style="position: absolute;z-index: 1;width: 100%;height: 50px;overflow: hidden;left: 0;bottom: 0;"
            >
              <svg viewbox="-10 -1 30 12">
                <defs>
                  <linearGradient
                    id="rectangleGradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="20%" stop-color="#393854" />
                    <stop offset="100%" stop-color="#100e1e" />
                  </linearGradient>
                </defs>
                <path
                  d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z"
                />
              </svg>
            </div>

            <div class="-right-wrapper">
				      <router-link to="/promotion" class="-item-wrapper -promotion">
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/gift.png"
                  />
                  <img
                    src="../assets/images/gift.png"
                    class="-ic-img"
                    alt="EZ Casino รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">โปรโมชั่น</span>
				</router-link>
              <a
                href="https://lin.ee/dbUW3JC"
                class="-item-wrapper -line"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/png"
                    srcset="../assets/images/shiba888-ic-mobile-left-1.png"
                  />
                  <img
                    src="../assets/images/shiba888-ic-mobile-left-1.png"
                    class="-ic-img"
                    alt="EZ Casino รูปไอคอนไลน์"
                    width="34"
                    height="34"
                  />
                </picture>
                <span class="-text">Line</span>
              </a>
            </div>
            <div class="-fully-overlay js-footer-lobby-overlay"></div>
          </div>
        </div>
      </div>
    <div class="-main-footer-container js-replace-main-footer-container">
	<footer class="x-footer ">
		<div class="container-fluid -footer-wrapper">
		<div class="-footer-bottom-wrapper">
			<div>
			<p class="mb-0" style="padding-top:20px;">
				Copyright © 2023 All Rights Reserved.
			</p>
			</div>
		</div>
		</div>
	</footer>
	</div>
	</div>
</template>
<script>
import firebase from 'firebase/app'
import router from './../router'
export default {
  name: 'Footer',
  computed: {
    user () {
      return this.$store.state.user.user
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  methods: {
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/login')
      }
    },
   async moveCreditToWallet(){
      this.$store.commit('user/SET_LOADING', true)
      const user = this.getUserId();
      this.$store.dispatch('user/moveCreditToWallet').finally(res => {
        this.$store.dispatch('user/getLastBonus', user).finally(res => {
          this.$store.commit('user/SET_LOADING', false)
          if(this.lastbonus.turncredit){
            this.$store.commit("user/SET_ALERT_WITHDRAWALL", true);
          }
        }).catch(err => { console.error(err) })
      }).catch(err => { console.error(err) })
    },
    async checkPromotions () {
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    }
  }
}
</script>
